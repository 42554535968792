$( document ).ready(function() {

    // Détection de l'OS et du navigateur pour choisir entre lissage ou pas

    var OSName="Unknown OS";
            if (navigator.appVersion.indexOf("Win")!=-1) OSName="Windows";
            if (navigator.appVersion.indexOf("Mac")!=-1) OSName="MacOS";
            if (navigator.appVersion.indexOf("X11")!=-1) OSName="UNIX";
            if (navigator.appVersion.indexOf("Linux")!=-1) OSName="Linux";

    // Opera 8.0+
    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    // At least Safari 3+: "[object HTMLElementConstructor]"
    var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    // Chrome 1+
    var isChrome = !!window.chrome && !!window.chrome.webstore;
    // Blink engine detection
    var isBlink = (isChrome || isOpera) && !!window.CSS;

    var smooth_scrolling_state = true;

    if(OSName == "Windows") {
        if(isFirefox == true) { var smooth_scrolling_state = true; }
        if(isChrome == true) { var smooth_scrolling_state = false; }
        if(isSafari == true) { var smooth_scrolling_state = true; }

    } else if(OSName == "MacOS") {
        if(isFirefox == true) { var smooth_scrolling_state = false; }
        if(isChrome == true) { var smooth_scrolling_state = false; }
        if(isSafari == true) { var smooth_scrolling_state = true; }
    }

     // On initialise skrollr seulement sur PC
    var supports = (function() {
        var d = document.documentElement,
            c = "ontouchstart" in window || navigator.msMaxTouchPoints;
        if (c) {
            // On est sur mobile, on initialise pas Skrollr
        } else {
            // INITIALISATION DE SKROLLR
            ( function( $ ) {
                // Init Skrollr
                var s = skrollr.init({
                    smoothScrolling: smooth_scrolling_state,
                    forceHeight: false,
                    render: function(data) {
                        //Debugging - Log the current scroll position.
                        //console.log(data.curTop);
                    }
                });
            } )( jQuery );
        }
    })();

});